import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl } from 'helpers'
import { FormattedMessage } from 'components'
import {
  Img,
  Flex,
  Layout,
  Section,
  Container,
  Box,
  Link,
  Features,
  WeOffer,
  Cases,
} from 'components'

const ScrollDown = styled(Flex)`
  position: absolute;
  bottom: 2em;
  width: 100%;
  font-size: 0.8em;
  color: #fff;
  span {
    margin: auto 0;
    white-space: pre;
    text-align: center;
    line-height: 1.4em;
    animation: scrollDownAnim 2s ease-in-out infinite alternate;
  }
  @keyframes scrollDownAnim {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
`

const HomePage = ({ intl }) => {
  return (
    <Layout>
      <Section fullpage>
        <Container>
          <Flex>
            <Box>
              <h1>
                <FormattedMessage id="homepage.title" />
              </h1>
              <p>
                <FormattedMessage id="homepage.description" />
              </p>
            </Box>
          </Flex>
        </Container>
        <ScrollDown center>
          <FormattedMessage id="homepage.scrollDown" />
        </ScrollDown>
      </Section>

      <Section>
        <Container>
          <WeOffer />
        </Container>
      </Section>

      <Section black>
        <Container>
          <Cases />
        </Container>
      </Section>

      <Section>
        <Container>
          <Features />
        </Container>
      </Section>
      <Section black>
        <Container>Blog section</Container>
      </Section>
      <Section black>
        <Container>
          <h1>Callback</h1>
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(HomePage)

// export const query = graphql`
//   query($node_locale: String!) {
//     cases: allContentfulCase(node_locale: { eq: $node_locale }) {
//       edges {
//         node {
//           id
//           title
//           slug
//         }
//       }
//     }
//   }
// `
